
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {TABLE_FOOTER_OPTIONS} from "@/misc/TableFooterOptions";
import PenaltyStatus from "@/models/PenaltyStatus";
import {PenaltyPaymentType} from "@/enum/PenaltyPaymentType.enum";

@Component
export default class PenaltyPaymentListComponent extends Vue {
  @Prop({required: true})
  public penaltyPayments!: PenaltyStatus[];

  @Prop({default: false})
  private isLoading!: boolean;

  @Prop({default: ""})
  private search!: string;

  /**
   * Container image
   * @private
   */
  private containerImage = require('../../assets/container_illustration_pure.svg');

  /**
   * options for the footer
   * @private
   */
  private footerOptions = TABLE_FOOTER_OPTIONS;

  private headers = [
    {
      text: this.$t("PENALTY_PAYMENT.NAME"),
      align: "start",
      sortable: true,
      value: "containerType.name",
    },
    {
      text: this.$t("GENERAL.DAY"),
      align: "start",
      sortable: true,
      value: "date",
      formatter: this.translateDate,
    },
    {
      text: this.$t("PENALTY_PAYMENT.AMOUNT"),
      align: "start",
      sortable: true,
      value: "income",
      currencyFormatter: this.translateToCurrency,
    },
  ];

  /**
   * translates the date with the custom translater plugin, checks if the date is set to avoid 'invalid dateTime's in
   * the table
   * @param date
   * @private
   */
  private translateDate(date?: string): string {
    // if date doesn't exist, just return placeholder
    if (!date) return "-";

    // return the translated date
    return this.$formatDateFromString(date);
  }

  private isPayWithMerways(status: PenaltyStatus) {
    return status.type === PenaltyPaymentType.PAY_WITH_MERWAYS;
  }

  private isPenaltyPayment(status: PenaltyStatus) {
    return status.type === PenaltyPaymentType.PENALTY_PAYMENT;
  }

  /**
   * translates the passed number to a currency sum
   * @param value
   * @private
   */
  private translateToCurrency(value: number) {
    return this.$formatCurrency(value!);
  }
}
